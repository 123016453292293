import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const TopBar = () => {
  const [showDropdown, setShowDropdown] = useState(false); // Estado para controlar el dropdown

  const toggleDropdown = () => setShowDropdown(!showDropdown); // Alternar visibilidad del dropdown
  const closeDropdown = () => setShowDropdown(false); // Cerrar el dropdown

  return (
    <nav style={styles.navBar}>
      <div style={styles.logoContainer}>
        <img src="../maxidinos.png" alt="Logo" style={styles.logo} />
        <h1 style={styles.siteTitle}>MaxiDinos</h1>
      </div>

      <ul style={styles.navList}>
        <li>
          <Link to="/" style={styles.navItem}>Inicio</Link>
        </li>
        <li>
          <Link to="/sobre-nosotros" style={styles.navItem}>Sobre Nosotros</Link>
        </li>
        <li style={styles.dropdownContainer}>
          <span style={styles.navItem} onClick={toggleDropdown}>Periodos ▼</span>
          {showDropdown && (
            <ul style={styles.dropdownMenu}>
              <li><Link to="/periodo-triasico" style={styles.dropdownItem} onClick={closeDropdown}>Triásico</Link></li>
              <li><Link to="/periodo-jurasico" style={styles.dropdownItem} onClick={closeDropdown}>Jurásico</Link></li>
              <li><Link to="/periodo-cretacico" style={styles.dropdownItem} onClick={closeDropdown}>Cretácico</Link></li>
            </ul>
          )}
        </li>
      </ul>
    </nav>
  );
};

const styles = {
  navBar: {
    backgroundColor: '#2c3e50', // Fondo oscuro
    padding: '10px 20px',
    position: 'fixed',
    width: '100%',
    top: -30,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
  },
  logo: {
    height: '50px',
    width: 'auto',
    marginTop: '20px',
  },
  siteTitle: {
    marginTop: '30px',
    color: '#ecf0f1', // Texto claro
    fontSize: '1.5rem',
    fontFamily: 'Arial, sans-serif',
    fontWeight: 'bold',
  },
  navList: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  navItem: {
    color: '#ecf0f1', // Texto claro
    textDecoration: 'none',
    fontSize: '1rem',
    fontFamily: "'Roboto', sans-serif",
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'color 0.3s ease',
    marginRight:'50px',
  },
  navItemHover: {
    color: '#1abc9c', // Color de hover
  },
  dropdownContainer: {
    position: 'relative',
  },
  dropdownMenu: {
    position: 'absolute',
    top: '110%',
    left: 0,
    backgroundColor: '#34495e', // Fondo del dropdown
    listStyleType: 'none',
    padding: '10px',
    margin: 0,
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    animation: 'fadeIn 0.3s ease',
  },
  dropdownItem: {
    padding: '10px 15px',
    textDecoration: 'none',
    color: '#ecf0f1',
    display: 'block',
    fontFamily: "'Roboto', sans-serif",
    fontSize: '0.9rem',
    transition: 'background-color 0.3s ease, color 0.3s ease',
  },
  dropdownItemHover: {
    backgroundColor: '#1abc9c', // Color de fondo al hover
    color: '#ffffff',
  },
};

export default TopBar;
