import React from 'react';
import DinosaurCarousel from '../components/carrousel.js';

const imageLinks = [
    // Aquí van tus 10,000 enlaces de imágenes de dinosaurios
    "http://fc02.deviantart.net/fs70/i/2012/063/d/f/shanag_ashile_by_teratophoneus-d4ro06p.jpg",
    "http://fc05.deviantart.net/fs71/i/2011/219/7/1/shanag_ashile_by_teratophoneus-d45qqi4.jpg",
    "http://www.nhm.ac.uk/resources/nature-online/life/dinosaurs/dinosaur-directory/images/reconstruction/small/shanag.jpg",
    "http://4.bp.blogspot.com/-V9fOI9vdRag/UTWdHs-fBAI/AAAAAAAAElM/lFV4-FZ7UjU/s1600/Shanag+reloaded.jpg",
    // Nuevos enlaces de Gasosaurus y otros dinosaurios
    "http://fc05.deviantart.net/fs70/f/2013/106/c/4/gasosaurus_by_gregoryferreira-d61ylq8.jpg",
    "http://3.bp.blogspot.com/-Uj4Nm-uqsx0/UIIJL3HVIiI/AAAAAAAAAsU/M8LYKFBp82g/s1600/Gasosaurus_20121019_1.jpg",
    "http://images.fineartamerica.com/images-medium-large/enhanced-image-of-a-gasosaurus-dinosaur-fossil-mehau-kulyk.jpg",
    "https://lh3.googleusercontent.com/-2SzxBoJd87M/UAxGk7Tj10I/AAAAAAAAAtM/h7NiwxKtWAg/s1152/Gasosaurus-Aardonyx-scale%252Bhuman.png",
    "http://fc05.deviantart.net/fs71/i/2013/125/e/5/the_skeleton_of_gasosaurus_by_darcygagnon-d6499f4.jpg",
    "http://upload.wikimedia.org/wikipedia/commons/1/13/Barapasaurus_DB.jpg",
    "http://file1.npage.de/004234/70/bilder/barapasaurus.jpg",
    "http://www.prehistoric-wildlife.com/images/species/b/barapasaurus-size.jpg",
    "https://jesperberge.files.wordpress.com/2010/03/barapasaurus.jpg",
    "http://fc02.deviantart.net/fs71/i/2012/199/9/0/038__barapasaurus_tagorei_by_green_mamba-d4zlpy4.png",
    "http://rlv.zcache.com/vintage_barapasaurus_dinosaur_with_storm_clouds_wallet-r5e77b8c3891c49fb9a83f9a12beb027d_ivw45_8byvr_512.jpg",
    "http://donglutsdinosaurs.com/wp-content/uploads/2011/08/Barapasaurus-skeleton-1000x5401-700x378.jpg",
    //
    "http://2.bp.blogspot.com/-1dp4ceaBHiQ/UPMexGjxDgI/AAAAAAAADNw/2YT3rQtRwVw/s1600/Trinisaura_NT.jpg",
    "http://images.fineartamerica.com/images-medium-large-5/trinisaura-santamartaensis-late-nobumichi-tamara.jpg",
    //
    "http://1.bp.blogspot.com/-HCT00Qk2UN8/UQ-Jj8567gI/AAAAAAAAOrI/qYb4Y3rwE28/s1600/Trinisaura01.jpg",
    "http://fc04.deviantart.net/fs71/i/2013/037/1/1/sleeping_trinisaura_by_hyrotrioskjan-d5u1ck8.jpg",
    "http://fc06.deviantart.net/fs70/i/2013/048/a/b/trinisaura_santamartaensis_by_tuomaskoivurinne-d5v8yxf.png",
    "http://th01.deviantart.net/fs71/PRE/i/2013/036/0/9/trinisaura_santamartaensis_by_teratophoneus-d5txj7z.jpg",
    "http://fc05.deviantart.net/fs71/i/2014/339/8/e/trinisaura_santamartaensis_by_trefrex-d88sl67.jpg",
    //

    "http://www.dinopit.com/wp-content/uploads/2012/08/kentrosaurus.png",
    "http://upload.wikimedia.org/wikipedia/commons/1/13/Kentrosaurus_02.JPG",
    //
    "http://fc01.deviantart.net/fs71/i/2013/139/2/5/kentrosaurus_aethiopicus_by_t_pekc-d65te57.jpg",
    "http://ayay.co.uk/backgrounds/dinosaurs/armoured/kentrosaurus-spikey-lizard.jpg",
    "http://thumbs.dreamstime.com/z/kentrosaurus-25119713.jpg",
    "http://images2.wikia.nocookie.net/__cb20120516220844/dtrain/images/2/20/Kentrosaurus.png",
    "http://thumbs.dreamstime.com/z/giant-kentrosaurus-dinosaur-9884997.jpg",
    "http://images.fineartamerica.com/images-medium-large-5/kentrosaurus-dinosaur-resting-white-elena-duvernay.jpg",
    "http://thumbs.dreamstime.com/z/kentrosaurus-25119727.jpg",
    "http://upload.wikimedia.org/wikipedia/commons/1/17/Kentrosaurus_005.JPG",
    "http://img3.wikia.nocookie.net/__cb20130217214505/dinosaurs/images/a/a2/Kentrosaurus3.jpg",
    "http://fc00.deviantart.net/fs70/i/2012/272/9/d/kentrosaurus_by_cormorana-d5gb14b.jpg",
    "http://images.fineartamerica.com/images-medium-large/kentrosaurus-dinosaur-artwork-roger-harris.jpg",
    "http://fc00.deviantart.net/fs71/f/2013/031/b/5/eobrontosaurus_by_brolyeuphyfusion9500-d5tckpl.png",
    "http://www.prehistoric-wildlife.com/images/species/e/eobrontosaurus-size.jpg",
    "http://th02.deviantart.net/fs71/PRE/i/2011/328/6/9/eobrontosaurus_and_lourinhasaurus_by_lipebrazilkombat-d4h761r.jpg",
    "http://fc07.deviantart.net/fs70/i/2012/113/9/6/eobrontosaurus_yahnahpin_by_teratophoneus-d4xal3a.jpg",
    "http://3.bp.blogspot.com/-mp97MXA1z9Q/TmJN4sKszsI/AAAAAAAACEw/5lN4r0KpObw/s1600/eocarcharia1.jpg",
    // Añade más enlaces según necesites...
];
  
const Home = () => {
  return (
      <div style={styles.pageContainer}>
          <div style={styles.heroSection}>
              <h1 style={styles.title}>🌍 Enciclopedia de Dinosaurios</h1>
              <p style={styles.subtitle}>Descubre el fascinante mundo de los dinosaurios</p>
          </div>
          <div style={styles.contentSection}>
              <DinosaurCarousel imageLinks={imageLinks} />
          </div>
          <footer style={styles.footer}>
              <p style={styles.footerText}>
                  🚀 Diseñado para los amantes de los dinosaurios | © 2024 MaxiDinos
              </p>
          </footer>
      </div>
  );
};

const styles = {
  pageContainer: {
      background: 'linear-gradient(to bottom, #3c3b6e, #243b55)',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '20px 10px',
      fontFamily: "'Roboto', sans-serif",
  },
  heroSection: {
      textAlign: 'center',
      color: '#ffffff',
      marginBottom: '40px',
  },
  title: {
      fontSize: '3rem',
      fontWeight: 'bold',
      color: '#ffd700', // Dorado para resaltar el título
      marginBottom: '10px',
      textShadow: '2px 2px 10px rgba(0,0,0,0.7)',
  },
  subtitle: {
      fontSize: '1.5rem',
      color: '#f0f0f0',
      textShadow: '1px 1px 5px rgba(0,0,0,0.5)',
      marginBottom: '30px',
  },
  contentSection: {
      width: '100%',
      maxWidth: '1200px',
      padding: '20px',
      borderRadius: '15px',
      boxShadow: '0 4px 10px rgba(0,0,0,0.3)',
      backgroundColor: '#ffffff',
  },
  footer: {
      textAlign: 'center',
      marginTop: '30px',
      color: '#ffffff',
      fontSize: '0.9rem',
  },
  footerText: {
      margin: 0,
  },
};

export default Home;